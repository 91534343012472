
import NavBar from "@/components/navBar.vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { ref, computed, Ref } from "vue";
import { Icon, Overlay } from "vant";
import { List } from "vant";
import { numberPackage, nativePay } from "@/api/request.js";
// import QRCode from "qrcodejs2"; // 生成二维码
export default {
  name: "numbers",
  components: {
    navbar: NavBar,
    [Icon.name]: Icon,
    [List.name]: List,
    [Overlay.name]: Overlay,
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const page = ref<number>(0);
    const count = ref<number>(10);
    const list = ref<Array<any>>([]);
    const loading = ref(false);
    const finished = ref(false);
    const show = ref(false);
    const detail = ref(null) as Ref<any>;
    const codeUrl = ref("");
    const openid = computed(() => store.state.openid);
    const payshow = ref(false);
    numberPackage({
      id: route.query.id,
      page: page.value,
      count: count.value,
    }).then((res: any) => {
      console.log(res, "@##$@@@");
    });
    const onLoad = async () => {
      console.log(33223322);

      page.value++;
      const data = await numberPackage({
        id: route.query.id,
        page: page.value,
        count: count.value,
      });

      data && (list.value = list.value.concat(data.data as any));
      console.log(data, "data", page.value,data.length);
      console.log(list.value, "list");

      // 加载状态结束
      loading.value = false;

      // 数据全部加载完成
      if (
        data && data.data.length < 1 || data && (data.data as any).length < count.value
      ) {
        finished.value = true;
        console.log(22);
      }
      if (data == undefined) {
        finished.value = true;
      }
    };
    // onLoad()
    interface listItem {
      context: number;
      madatoryReq: string;
      niceType: number;
      number: string;
      price: number;
      productName: string;
      starts: number;
      status: number;
    }
    const getshow = (item: listItem) => {
      console.log(item, "item");
      detail.value = item;
      show.value = true;
    };
    const confirm = () => {
      show.value = false;
      const t = new Date();
     
        router.push({
          path: "/purchase/order",
          query: {
            t: t.getTime(),
            number: detail.value.number,
            id: detail.value.niceType,
            type:'1',
            productName:detail.value.productName,
            madatoryReq:detail.value.madatoryReq,
            price:detail.value.price,
            oid:route.query.id
          },
        });
      
    };
    const getnativePay = async () => {
      const { data } = await nativePay({ code: 111 });
      console.log(data, "pay");
      codeUrl.value = data.codeUrl;
    };
    // getnativePay()
    // const GenerateQRcode = () => {
    //   console.log(123);

    //   new QRCode("payQrcode", {
    //     // 此处的qrcode为上面div的id
    //     text: codeUrl.value,
    //     width: 200,
    //     height: 200,
    //     colorDark: "#000000",
    //     colorLight: "#ffffff",
    //     correctLevel: QRCode.CorrectLevel.H,
    //   });
    // };
    const getnumber = () => {
      router.push({ name: "number" });
    };
    const onClickLeft = () => {
      router.push({ path: "/purchase/combo" });
    };

    return {
      onLoad,
      list,
      loading,
      finished,
      getshow,
      show,
      detail,
      confirm,
      payshow,
      getnumber,
      onClickLeft,
    };
  },
};
