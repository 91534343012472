
import { NavBar } from "vant";
import { Icon } from "vant";
export default {
  name: "nav",
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
  },
  props: {
    title: {
      typeof: Object,
      default: "",
    },
    color: {
      typeof: String,
      default: "",
    },
    background: {
      typeof: String,
      default: "#f4f4f4",
    },
  },
  emits: ["onClickLeft"],
   // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(props:any,cxt:any) {
    const onClickLeft = () => {
      cxt.emit('onClickLeft')
      console.log("返回上一页");
    };
    const onClickRight = () => {
      console.log("按钮");
    };

    return {
      onClickLeft,
      onClickRight,
    };
  },
};
